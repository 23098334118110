import React, { useEffect } from "react";
import { useRef } from "react";
import "./Testimonial.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Testimonals = () => {
  // let totalclickref=useRef(0);
  // const totalimpressionref=useRef(0);
  // const[countchange,setCountchange]=useState(totalimpressionref.current)
  // const [once,setOnce]=useState(false);

  // const observer = React.useRef();
  // let totalclicktimeout=useRef(null);
  // let totalimpression=useRef(null);
  // const intervalchange = ()=>{
  //   totalclicktimeout = setInterval(() => {
  //      totalclickref.current=totalclickref.current+1;
  //      if(totalclickref.current==157){
  //       clearInterval(totalclicktimeout);
  //      }
  //    }, 45);

  //    totalimpression = setInterval(() => {
  //     totalimpressionref.current=totalimpressionref.current+1;
  //     if(totalimpressionref.current==766){
  //      clearInterval(totalimpression);
  //     }
  //     setCountchange(totalimpressionref.current);
  //   }, 10);
  //  }

  // const markerRef = React.useCallback(
  //   (node) => {
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && !once) {
  //         console.log("akaehs")
  //         setOnce(true);
  //           intervalchange();
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   }
  //   ,
  //   // [setStartIndex, emptyFetch]
  // )

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%" }}
        onClick={onClick}
      >
        <FaAngleRight className="text-teal-500 text-2xl " />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%" }}
        onClick={onClick}
      >
        <FaAngleLeft className="text-teal-500 text-2xl " />
      </div>
    );
  }

  let sliderRef = useRef(null);
  const settings = {
    speed: 1500,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  useEffect(() => {
    sliderRef.slickPlay();
  }, []);

  const testimonialsection = [
    {
      Name: "Wellness Center",
      designation: "IIT Patna",
      image: "./img/Indian_Institute_of_Technology,_Patna.svg.png",
      description:
        "I want to express my heartfelt appreciation for the outstanding effort by the Clinical AI Assistance team put into developing the mental health assessment platform. The dedication and hard work are truly commendable. They always go one step ahead, think more clearly, and provide an efficient and scalable solution. Thank you for taking on this project, which will undoubtedly be a milestone in mental health assistance for educational institutions. Best wishes for all future endeavors",
    },
    {
      Name: "Shivam Gupta",
      designation: "Revenue Operations Manager at UTHO",
      image: "./img/shivam_gupta.jpg",
      description:
        "Thrilled to connect with the brilliant minds of Clinical AI Assistance Pvt Ltd at the GPAI Summit 2023! Exciting to see how these talented IITians are harnessing the power of AI to address the challenges of long queues in hospitals. Their innovative solutions are not just transforming patient experiences but shaping the future of healthcare. Clinical AI Assistance Abhisek Tiwari Robin Kudos to their commitment to making a meaningful impact! ",
    },
    {
      Name: "Dr. Viyas",
      designation: "Doctor at AIIMS Rishikesh",
      image: "./img/logo dark updated.png",
      description:
        " I appreciate the effort made by the Clinical AI Assistance team in establishing US, a community for children with Autism. Their dedication has been instrumental in supporting both caregivers and children, offering assistance in assessment, development, and therapist booking",
    },
    {
      Name: "Rajan Tawate",
      designation: "Innovations Incharge at NPCI NBBL",
      image: "./img/rajan_tawate.jpg",
      description:
        " Met the Founders of Clinical AI Assistance . Promising product,which can revolutionize Medical Assistance especially suitable for developing countries.",
    },
  ];

  return (
    <>
      <section>
        {/* <div className='countersection' ref={markerRef}>
        <div className='firstcountersection'>
          <div className='counteronly'>
{totalclickref.current}+
</div>
<div className='counterdata'>Total Visitors</div>
        </div>
        <div className='secondcountersection'>
        <div className='counteronly'>
        {totalimpressionref.current}+
        </div>
<div className='counterdata'>Total Impressions</div>
        </div>
    </div> */}
      </section>
      <section className="bg-white">
        <h3 className="testimonialheader text-3xl font-bold bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent mb-4">
          Testimonial
        </h3>
        {/* <div className='cardsection'> */}
        <Slider
          className="cardsection h-fit 2xl:h-[15rem] rounded-md bg-teal-100"
          ref={(slider) => (sliderRef = slider)}
          {...settings}
        >
          {testimonialsection.map((item, index) => {
            return (
              <>
                <div className="testimonialcard pb-4 h-fit 2xl:h-[14rem]">
                  <div className="imagesection">
                    <img src={item.image} alt="logo"></img>
                    <div>
                      <h2 className="nameheading">{item.Name}</h2>
                      <h4 className="designationheading">{item.designation}</h4>
                    </div>
                  </div>
                  <div className="cardbodysection">
                    {/* <h2>{item.Name}</h2>
                    <h4>{item.designation}</h4> */}
                    <p>{item.description}</p>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
        {/* </div> */}
      </section>
    </>
  );
};

export default Testimonals;
