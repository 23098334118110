import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./Accomplishments.css";
import Collaborations from "./Collaborations";
import useSliderVisibilityManagement from "../hooks/useSliderVisibilityManagement";
const Accomplishments = () => {
  // const navigate = useNavigate();
  const sliderRef = useRef(null);
  const accomplishments = [
    {
      title: "GPAI 2023 Nomination",
      description:
        "Selected as one of the ten outstanding projects from Bihar.",
      img: "./img/gpai.jfif",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7141489381562851329/",
    },
    {
      title: "Engineer's Conclave 2023",
      description: "Secured 3rd position among 20 IITs at IIT Madras.",
      img: "./img/engEnc.jpeg",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7147284787328606208/",
    },
    {
      title: "Hult Prize 2024",
      description: "Achieved runner-up position in IIT Patna on-campus event.",
      img: "./img/hult.jfif",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7164956095524859904/",
    },
    {
      title: "Media Feature",
      description:
        "Research work featured in Times of India, Prabhat Khabar & TEDxIITPatna Talks.",
      img: "./img/media.png",
      link: "https://timesofindia.indiatimes.com/city/patna/iit-patna-scholars-developing-virtual-doctor-for-early-diagnosis-of-diseases/articleshow/94141545.cms",
    },
    {
      title: "Patent Filing",
      description:
        "System and Method for Automated Disease Diagnosis, Jan 2023.",
      img: "./img/patent.jfif",
      link: "https://www.linkedin.com/posts/clinical-ai-assistance_clinicalai-medtechinnovation-healthtech-activity-7154336931990929408-RJ3m/?utm_source=share&utm_medium=member_desktops",
    },
  ];

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%" }}
        onClick={onClick}
      >
        <FaAngleRight className="text-teal-500 text-2xl " />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%" }}
        onClick={onClick}
      >
        <FaAngleLeft className="text-teal-500 text-2xl " />
      </div>
    );
  }

  const settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
          dots: true,
          autoplaySpeed: 4000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
          dots: true,
          autoplaySpeed: 4000,
        },
      },
    ],
  };

  useSliderVisibilityManagement(sliderRef);

  return (
    <div className="pb-24 bg-white px-2 sm:px-4">
      <div className="container mx-auto">
        <Collaborations />
        {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-1 sm:gap-4 mb-8 bg-teal-100 p-1 sm:p-4 rounded-lg xl:mx-6">
          <div className="bg-white shadow p-2 rounded-lg flex flex-col items-center">
            <h3 className="text-base sm:text-lg md:text-xl font-semibold h-10 sm:h-16 flex items-center">
              Ayushman Bharat Digital Mission
            </h3>

            <img
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/feed/update/urn:li:activity:7199482988047310849/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              src="./img/ABDM.png"
              className="h-32 sm:h-40"
              alt="Ayushman Bharat Digital Mission"
            />
            <p className="text-gray-600 text-center text-sm sm:text-base">
              Transforming digital healthcare data management.
            </p>
          </div>
          <div className="bg-white shadow p-2 rounded-lg flex flex-col items-center">
            <h3 className="text-base sm:text-lg md:text-xl font-semibold h-10 sm:h-16 flex items-center ">
              CDAC Kolkata
            </h3>
            <img
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/posts/clinical-ai-assistance_disaaa-us-empowermentinunity-activity-7180865458009513985-2ANM/?utm_source=share&utm_medium=member_desktop",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              src="./img/cdac.jfif"
              className="h-32 sm:h-40"
              alt="CDAC Kolkata"
            />
            <p className="text-gray-600 text-center text-sm sm:text-base">
              Collaboration in technology and healthcare.
            </p>
          </div>
          <div className="bg-white shadow p-2 rounded-lg flex flex-col items-center">
            <h3 className="text-base sm:text-lg md:text-xl font-semibold h-10 sm:h-16 flex items-center">
              IIT Patna
            </h3>
            <img
              onClick={() => navigate("/")}
              src="./img/IITPatnaLogo.png"
              className="h-32 sm:h-40"
              alt="IIT Patna"
            />
            <p className="text-gray-600 text-center text-sm sm:text-base">
              Academic and research partnership.
            </p>
          </div>
          <div className="bg-white shadow p-2 rounded-lg flex flex-col items-center">
            <h3 className="text-base sm:text-lg md:text-xl font-semibold h-10 sm:h-16 flex items-center">
              Startup India
            </h3>
            <img
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/feed/update/urn:li:activity:7150940824464171008/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              src="./img/startup_india.png"
              className="h-32 sm:h-40"
              alt="Startup India"
            />
            <p className="text-gray-600 text-center text-sm sm:text-base">
              We are recognized as a Healthcare startup by Startup India.
            </p>
          </div>
        </div> */}

        <h2 className="text-center mb-4 mt-24">
          <span className="text-3xl font-bold text-center bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent">
            Accomplishments
          </span>
        </h2>
        <Slider ref={sliderRef} {...settings}>
          {accomplishments.map((acc, index) => (
            <div key={index} className="px-2 xl:px-8">
              <div className="rounded-lg h-[24rem] bg-teal-100 md:hover:bg-teal-300 pb-4">
                <img
                  src={acc.img}
                  alt=""
                  className="w-full h-64 rounded-t-lg border border-b-0"
                ></img>
                <h3
                  onClick={() =>
                    window.open(acc.link, "_blank", "noopener,noreferrer")
                  }
                  className="text-xl px-6 font-semibold mb-2 cursor-pointer hover:underline mt-2 text-gray-800"
                >
                  {acc.title}
                </h3>
                <p className="mb-4 px-6 h-20 md:h-16 text-gray-800 text-sm">
                  {acc.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Accomplishments;
