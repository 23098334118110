import React, { useEffect } from "react";
import styles from "./SwaasthyaSathi.module.css";

const SwaasthyaSathi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.outerss}>
      <div className={styles.space}></div>
      <div className={styles.headingss}>
        <div className={styles.h1}>Dr. Aayush</div>
      </div>
      <div className="flex sm:ml-2 items-center px-3 lg:px-0 text-left">
        <div className="flex-2 sm:flex-1 text-justify text-xs sm:text-sm md:text-base">
          <p className={styles.about}>
            Dr. Aayush aids doctors in conducting symptom investigations and
            diagnosing diseases. It is accessible for clinicians and healthcare
            professionals only. If you are interested in exploring the
            assistant, please fillout the below form for joining waiting list
            and demo request.
          </p>
        </div>
        <div className="flex-1">
          <img
            src="./img/doc_aayush.png"
            alt="Dr. Aayush"
            className="w-48 h-48 rounded-lg my-0 mx-auto hidden sm:flex shadow-lg"
          />
        </div>
      </div>
      <img
        className={`${styles.cabimg} shadow-lg`}
        src=".\img\Dr Aayush.png"
        alt=""
      />
      <br />
      <button
        onClick={() => {
          window.open(
            "https://calendly.com/clinicalaiassistance/demo",
            "_blank"
          );
        }}
        className={`${styles.demoBookbtn} transition duration-200 hover:scale-105`}
      >
        Demo Request
      </button>
      <div className={styles.space}></div>
    </div>
  );
};

export default SwaasthyaSathi;
