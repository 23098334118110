import React from "react";
import styles from "./Teams.module.css";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoGoogle } from "react-icons/io5";
import { BsLinkedin } from "react-icons/bs";

const data = [
  {
    name: "Dr. Abhisek Tiwari",
    img: "./img/Abhisek.jpeg",
    mail: "abhisektiwari2014@gmail.com",
    id: "https://abhisektiwari.github.io/",
    lkin: "https://www.linkedin.com/in/abhisekti/",
    twit: "https://twitter.com/abhisekti",
    qua: "BTech, PhD",
  },
  {
    name: "Dr. Rajvardhan Bhanwar",
    img: "./img/cofounder.jpeg",
    mail: "dr.rajvardhan.md@gmail.com",
    id: "http://www.mindfulpsychiatristindore.com/",
    lkin: "https://www.linkedin.com/in/rajvardhan-bhanwar-md-96830b212/",
    twit: "https://clinicalaiassistance.com/teams",
    qua: "MBBS, MD",
  },
];

const data1 = [
  {
    name: "Dr. Sriparna Saha",
    img: "./img/ss.jpg",
    id: "https://www.iitp.ac.in/~sriparna/",
    lkin: "https://www.linkedin.com/in/sriparna-saha-1a1338161/?originalSubdomain=in",
    twit: "https://www.iitp.ac.in/~sriparna/",
    qua: "Associate Professor, IIT Patna",
  },
  {
    name: "Dr. Pushpak Bhattacharyya",
    img: "./img/Pushpak Bhattacharyya.jpeg",
    id: "https://www.cse.iitb.ac.in/~pb/",
    lkin: "https://in.linkedin.com/in/pushpakbh",
    twit: "https://www.cse.iitb.ac.in/~pb/",
    qua: "Professor, IIT Bombay",
  },
  {
    name: "Mr. Mukesh Jain",
    img: "./img/Mukesh.jpeg",
    id: "https://www.linkedin.com/in/mukeshjaincoach/",
    lkin: "https://www.linkedin.com/in/mukeshjaincoach/",
    twit: "https://www.linkedin.com/in/mukeshjaincoach/",
    qua: "CTO & VP at Capgemini",
  },
  {
    name: "Dr. Praveen Kumar",
    img: "./img/DrPraveen.jpg",
    id: "https://fistiitp.com/",
    lkin: "https://www.linkedin.com/in/fist-tbi-iit-patna-7227b9255/",
    twit: "https://fistiitp.com/",
    qua: "CEO, FIST-TBI, IIT Patna",
  },
];

const data2 = [
  {
    name: "Dr. Minakshi Dhar",
    img: "./img/Minakshi-Dhar.jpg",
    id: "https://www.researchgate.net/profile/Minakshi-Dhar",
    lkin: "https://www.linkedin.com/in/minakshi-dhar-64134319b/?originalSubdomain=in",
    twit: "https://www.linkedin.com/in/minakshi-dhar-64134319b/?originalSubdomain=in",
    qua: "AIIMS Rishikesh, MD, PGDGM, FIACM FACP",
  },
  {
    name: "Dr. Sai Ruthvik Edara",
    img: "./img/Ruthvik.jpeg",
    id: "https://www.linkedin.com/in/ruthvikedara/",
    lkin: "https://www.linkedin.com/in/ruthvikedara/",
    twit: "https://www.linkedin.com/in/ruthvikedara/",
    qua: "RA at IISc, MBBS",
  },

  {
    name: "Dr. Meghraj P",
    img: "./img/Meghraj.jpeg",
    id: "https://www.linkedin.com/in/megharaj-p-a0968523b/",
    lkin: "https://www.linkedin.com/in/megharaj-p-a0968523b/",
    twit: "https://www.linkedin.com/in/megharaj-p-a0968523b/",
    qua: "M.Tech, Medical Technology, MBBS",
  },

  {
    name: "Dr. Pankaj",
    img: "./img/DrRakesh.jpeg",
    id: "https://aiimspatna.edu.in/doctor/drpankajk@aiimspatna.org",
    lkin: "https://aiimspatna.edu.in/doctor/drpankajk@aiimspatna.org",
    twit: "https://aiimspatna.edu.in/doctor/drpankajk@aiimspatna.org",
    qua: "AIIMS Patna, MBBS, MD",
  },
  // {
  //     name: "Dr. Swarnim",
  //     img: "./img/Sawarnin.jpeg",
  //     id: "https://www.researchgate.net/profile/Minakshi-Dhar",
  //     lkin: "https://www.linkedin.com/in/minakshi-dhar-64134319b/?originalSubdomain=in",
  //     twit: "https://www.linkedin.com/in/minakshi-dhar-64134319b/?originalSubdomain=in",
  // },

  {
    name: "Dr. Vaibhav Sethi ",
    img: "./img/Vaibhav-Sethi.jpeg",
    id: "https://www.linkedin.com/in/dr-vaibhav-sethi-7930961b5/",
    lkin: "https://www.linkedin.com/in/dr-vaibhav-sethi-7930961b5/",
    twit: "https://www.linkedin.com/in/dr-vaibhav-sethi-7930961b5/",
    qua: "Product Management Intern IIHMR Delhi",
  },

  // {
  //     name: "Dr. Mrinmoy",
  //     img: "./img/mrinmoy.jpeg",
  //     id: "https://aiimspatna.edu.in/doctor/dr.swarnin11439@aiimspatna.org",
  //     lkin: "https://aiimspatna.edu.in/doctor/dr.swarnin11439@aiimspatna.org",
  //     twit: "https://aiimspatna.edu.in/doctor/dr.swarnin11439@aiimspatna.org",
  // },
];
const data3 = [
  {
    name: "Dr. Abhisek Tiwari",
    img: "./img/Abhisek Tiwari.jpg",
    id: "https://abhisektiwari.github.io/",
    lkin: "https://www.linkedin.com/in/abhisekti/",
    twit: "https://twitter.com/abhisekti",
    qua: "BTech, PhD",
  },
  {
    name: "Soumen Singh",
    img: "./img/soumen.jpg",
    id: "https://www.linkedin.com/in/soumen-singh-891844208/?originalSubdomain=in",
    lkin: "https://www.linkedin.com/in/soumen-singh-891844208/?originalSubdomain=in",
    twit: "https://www.linkedin.com/in/soumen-singh-891844208/?originalSubdomain=in",
    qua: "BTech",
  },
  {
    name: "Ujjwal Kumar",
    img: "./img/ujjwal.jpg",
    id: "https://www.linkedin.com/in/masteryodaa",
    lkin: "https://www.linkedin.com/in/masteryodaa",
    twit: "https://x.com/masteryoda_69",
    qua: "BTech",
  },
  {
    name: "Avinash Singh",
    img: "./img/Avinash.jpeg",
    id: "https://www.linkedin.com/in/avinashs46/",
    lkin: "https://www.linkedin.com/in/avinashs46/",
    twit: "https://www.linkedin.com/in/avinashs46/",
    qua: "MCA, BHU",
  },
  {
    name: "Mayank Dhyani",
    img: "./img/mayank.jpeg",
    id: "https://www.linkedin.com/in/mayank-dhyani-897275208/",
    lkin: "https://www.linkedin.com/in/mayank-dhyani-897275208/",
    twit: "https://www.linkedin.com/in/mayank-dhyani-897275208/",
    qua: "Visual Designer",
  },
  {
    name: "Varad Paswar",
    img: "./img/varad Paswar.jpeg",
    id: "https://www.researchgate.net/",
    lkin: "https://www.linkedin.com/",
    twit: "https://www.linkedin.com/",
    qua: "MTech, IIT Patna",
  },
];

const sandm = [
  {
    name: "Robin Singh",
    img: "./img/robin.jpg",
    id: "https://instagram.com/robin__singh04?igshid=NGVhN2U2NjQ0Yg==",
    lkin: "https://www.linkedin.com/in/robin-singh-46b703208/",
    twit: "https://instagram.com/robin__singh04?igshid=NGVhN2U2NjQ0Yg==",
    qua: "BTech, IIT Patna",
  },

  {
    name: "Kripa Tiwari",
    img: "./img/Kripa.jpeg",
    id: "https://www.medaicare.in/teams",
    lkin: "https://www.medaicare.in/teams",
    twit: "https://www.medaicare.in/teams",
    qua: "Business Studies, UG & PG",
  },

  // {
  //     name: "Ankna Litoriya",
  //     img: "./img/Ankana.jpg",
  //     id: "https://www.linkedin.com/in/ankna",
  //     lkin: "https://www.linkedin.com/in/ankna",
  //     twit: "https://www.linkedin.com/in/ankna",
  //     qua: ""
  // },
  {
    name: "Yash Kumar",
    img: "./img/Yash.jpg",
    id: "https://www.linkedin.com/in/yash-kumar-9919a7286/",
    lkin: "https://www.linkedin.com/in/yash-kumar-9919a7286/",
    twit: "https://www.linkedin.com/in/yash-kumar-9919a7286/",
    qua: "M.Sc Mathematics, IIT Patna",
  },
  {
    name: "Ankita Priya",
    img: "./img/Ankita.jpg",
    id: "https://www.linkedin.com/in/ankita-priya-946923228",
    lkin: "https://www.linkedin.com/in/ankita-priya-946923228",
    twit: "https://www.linkedin.com/in/ankita-priya-946923228",
    qua: "BTech, NIT Arunachal Pradesh",
  },
  {
    name: "Akshay Rathore",
    img: "./img/akshay_rathore.jfif",
    lkin: "https://www.linkedin.com/in/aakshayrathore/",
    twit: "https://www.linkedin.com/in/aakshayrathore/",
    id: "https://www.linkedin.com/in/aakshayrathore/",
    qua: "MBA, IIM Bangalore",
  },
];
const data_r = [
  {
    name: "Dr. Abhisek Tiwari",
    img: "./img/Abhisek Tiwari.jpg",
    id: "https://abhisektiwari.github.io/",
    lkin: "https://www.linkedin.com/in/abhisekti/",
    twit: "https://twitter.com/abhisekti",
    qua: "BTech, PhD",
  },
  {
    name: "Vaibhav Rathore",
    img: "./img/Vaibhav.jpeg",
    id: "https://www.linkedin.com/in/vaibhav-rathore-ab696b188/",
    lkin: "https://www.linkedin.com/in/vaibhav-rathore-ab696b188/",
    twit: "https://www.linkedin.com/in/vaibhav-rathore-ab696b188/",
    qua: "MS by Research, IIT Bombay",
  },

  {
    name: "Bhavya Gupta",
    img: "./img/Bhavya.jpg",
    id: "https://www.linkedin.com/in/bhavya-gupta-685325163",
    lkin: "https://www.linkedin.com/in/bhavya-gupta-685325163",
    twit: "https://www.linkedin.com/in/bhavya-gupta-685325163",
    qua: "MTech, IIT Kanpur",
  },

  {
    name: "Aman Bhansali",
    img: "./img/aman.jpeg",
    id: "https://aman-095.github.io/",
    lkin: "https://www.linkedin.com/in/aman-bhansali-b4aa26228",
    twit: "https://aman-095.github.io/",
    qua: "BTech, IIT Jodhpur",
  },

  {
    name: "Yuvraj Singh",
    img: "./img/yuvraj.jpeg",
    id: "https://yuvraj-singh-portfoilio.onrender.com",
    lkin: "https://www.linkedin.com/in/yuvraj-singh-95b203289/",
    twit: "https://www.linkedin.com/in/yuvraj-singh-95b203289/",
    qua: "BTech, IIIT Bhubhneshwar",
  },

  {
    name: "Anisha Saha",
    department:
      "Expertise: Data Science, Data Scientist at Micron Technology | CMI Data Science '22| Summer Intern at Legato Health Technologies",
    experience: "Experience: 5 years +",
    hospital:
      "Avalability: S1: Sat 4.00 pm-4.30 pm, \n  S2: Sat 4.30 pm-5.00 pm, \n S3: Sun 4.00 pm-4.30 pm, \n S4: Sun 4.30 pm-5.00 pm",
    img: "./img/Anisha Saha.jpeg",
    // link: "https://forms.gle/gT6yJjKcCEwdonSi8",
    id: "https://www.linkedin.com/in/anisha0398/",
    qua: "MS, CMI",
  },
  {
    name: "Sanjana Sharma",
    img: "./img/sanjana.jpeg",
    // link: "https://forms.gle/gT6yJjKcCEwdonSi8",
    id: "https://www.linkedin.com/in/sanajanasharma9/",
    lkin: "https://www.linkedin.com/in/sanajanasharma9/",
    twit: "https://www.linkedin.com/in/sanajanasharma9/",
    qua: "BTech, JIIT Noida",
  },
];
const Teams = () => {
  return (
    <>
      <div className={styles.headertm}>
        <div className={styles.Drheader}>
          <h1>Founders</h1>
        </div>
        <div className={styles.headertm}>
          {data.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
                <a
                  href={`mailto:${elm.mail}`}
                  className={`${styles.mail} hover:underline`}
                >
                  {elm.mail}
                </a>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>

                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* 2nd row */}
        <div className={styles.Drheader}>
          <h1>Advisory Team</h1>
        </div>
        <div className={styles.headertm}>
          {data1.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 3rd row */}
        <div className={styles.Drheader}>
          <h1>Research Team</h1>
        </div>
        <div className={styles.headertm}>
          {data_r.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 4th row */}
        <div className={styles.Drheader}>
          <h1>Clinical Team</h1>
        </div>
        <div className={styles.headertm}>
          {data2.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 5th row */}
        <div className={styles.Drheader}>
          <h1>Engineering Team</h1>
        </div>
        <div className={styles.headertm}>
          {data3.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 6th row */}
        <div className={styles.Drheader}>
          <h1>Sales & Marketing Team</h1>
        </div>
        <div className={styles.headertm}>
          {sandm.map((elm) => (
            <div className={styles.menber}>
              <a href={elm.id}>
                <img className={styles.img} src={elm.img} alt="" />
              </a>
              <div className={styles.text}>
                <div className={styles.name}>{elm.name}</div>
                <div className={styles.qua}>{elm.qua}</div>
              </div>
              <div className={styles.socialicons}>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.id} target="_blank">
                    <IoLogoGoogle className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.twit} target="_blank">
                    <FaXTwitter className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
                <div className={styles.icondiv}>
                  <a rel="noreferrer" href={elm.lkin} target="_blank">
                    <BsLinkedin className="text-teal-600 text-2xl transition transform ease-in-out duration-200 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 7th row */}
      </div>
    </>
  );
};

export default Teams;
