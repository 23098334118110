import { useEffect } from 'react';

const useSliderVisibilityManagement = (sliderRef) => {
  useEffect(() => {
    const sliderElement = sliderRef.current?.innerSlider?.list;

    if (!sliderElement) return;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        sliderRef.current.slickPause();
      } else {
        sliderRef.current.slickPlay();
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            sliderRef.current.slickPlay(); 
          } else {
            sliderRef.current.slickPause(); 
          }
        });
      },
      {
        threshold: 0.1 
      }
    );

    observer.observe(sliderElement);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      observer.unobserve(sliderElement);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [sliderRef]);
};

export default useSliderVisibilityManagement;
