import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const Dropdown = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="pb-4">
      <button
        className="w-full p-4 bg-teal-100 border rounded flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <span>{isOpen ? <FaAngleUp/> : <FaAngleDown/>}</span>
      </button>
      {isOpen && (
        <div className="mt-1 bg-teal-100 border rounded px-4">
          {content.map((item, index) => (
            <p key={index} className='my-4'>
              <span className='font-bold'>{index+1}.</span> {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
