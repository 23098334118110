import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ series, labels }) => {
  const options = {
    chart: {
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
        
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, 
        columnWidth: '50%',
        endingShape: 'rounded',
        borderRadius: 5,
        borderRadiusApplication: 'end',
        distributed: true 
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Percentages'
      }
    },
    colors: ['#36a2eb', '#ff6384', '#ffce56', '#4caf50', '#27ECD1', '#76BFFF'],
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(1)}%`,
        title: {
          formatter: () => '', 
        },
      },
    }
  };

  return (
    <div className="sm:w-[95%] mx-auto">
      <Chart options={options} series={[{ data: series }]} type="bar" height="350" />
    </div>
  );
};
export default BarChart;
