import React, { useState, useRef, useEffect } from "react";
import "./US.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { collection, addDoc } from "firebase/firestore"; 
import { db } from "../Firebase";

const US = () => {
  const subRef = useRef(null);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
  });
  const [subscribed, setSubscribed] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSubscribed(false); // Reset subscribed status on input change
  };

  const handlePhoneNumberChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
    setValidPhoneNumber(true); // Reset phone validation status on input change
    setSubscribed(false);
  };

  const handleScroll = () => {
    subRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d{1,3}\d{9,12}$/; // Example: international phone number
    return phoneRegex.test(phone);
  };

  const handleSubmition = async () => {
    const isEmailValid = validateEmail(formData.email);
    const isPhoneValid = validatePhone(formData.phone);

    if (!isEmailValid) {
      setValidEmail(false);
    }
    if (!isPhoneValid) {
      setValidPhoneNumber(false);
    }

    if (isEmailValid && isPhoneValid) {
      try {
        // Send data to Firebase
        await addDoc(collection(db, "contacts"), {
          email: formData.email,
          phone: formData.phone,
          timestamp: new Date(),
        });
        setSubscribed(true);
        setFormData({ email: "", phone: "" }); // Reset form after successful submission
        setValidEmail(true);
        setValidPhoneNumber(true);
        alert("Subscribed")
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="main-container flex flex-col md:flex-row items-center justify-center w-full py-10 xl:px-20 2xl:px-40 mx-auto px-2 sm:px-6">
        <div className="md:w-1/2 lg:w-3/5 w-full">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4 animate-fade-in text-center md:text-left">
            US: Embrace the Uniqueness
          </h1>
          <p className="text-base md:text-lg md:leading-5 lg:leading-7 text-gray-600 animate-slide-up sm:mb-4 md:text-left text-justify">
            Welcome to our platform where we celebrate the unique qualities of
            every individual. Our mission is to provide resources, support, and
            community for autistic children and their families. Together, let's
            create an environment where everyone can thrive. We believe in the
            power of acceptance and strive to make a positive impact on every
            life we touch.
          </p>
          <div className="md:flex justify-center md:justify-start rounded-full hidden">
            <button
              onClick={handleScroll}
              className="bg-white w-full sm:w-auto px-5 py-2 rounded-full border font-semibold text-lg flex justify-center hover:scale-105 transition duration-300"
            >
              <span className="bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent">
                Register Now
              </span>
            </button>
          </div>
        </div>

        <div className="md:w-1/2 lg:w-2/5 w-full mb-6 md:mb-0">
          <div className="flex justify-center">
            <img
              src="./img/usbg.png"
              alt="AI Assistance"
              className=" size-72 sm:size-96 mx-auto rounded-3xl md:px-0"
            />
          </div>
          <div className="md:hidden flex justify-center md:justify-start rounded-full mt-4">
            <button
              onClick={handleScroll}
              className="bg-white w-full sm:w-80 md:w-auto px-5 py-2 rounded-full border font-semibold text-lg flex justify-center hover:scale-105 transition duration-300"
            >
              <span className="bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent">
                Register Now
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white w-full py-16">
        <h2 className="text-4xl font-semibold text-center text-gray-800 mb-12 animate-fade-in">
          What We Offer
        </h2>
        <div className="flex flex-wrap justify-center gap-8 px-4">
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg shadow-lg p-6 max-w-sm transform transition-transform duration-300 hover:scale-105">
            <img
              src="./img/resources.jpg"
              alt="AI Assistance"
              className=" size-48 border rounded-full mx-auto md:px-0"
            />
            <h3 className="text-2xl font-semibold text-yellow-800 mb-4">
              Resources
            </h3>
            <p className="text-gray-600">
              With a vast array of information on autism, from early signs to
              advanced therapeutic techniques, the app is an invaluable tool for
              both caregivers and the general public.
            </p>
          </div>
          <div className="bg-blue-50 border border-blue-200 rounded-lg shadow-lg p-6 max-w-sm transform transition-transform duration-300 hover:scale-105">
            <img
              src="./img/care.jpg"
              alt="AI Assistance"
              className=" size-48 border rounded-full mx-auto md:px-0"
            />
            <h3 className="text-2xl font-semibold text-blue-800 mb-4">
              Personalized Care
            </h3>
            <p className="text-gray-600">
              Caregivers can create customized care plans tailored to their
              child's unique needs, helping manage daily routines, therapies,
              and behavioral strategies effectively.
            </p>
          </div>

          <div className="bg-green-50 border border-green-200 rounded-lg shadow-lg p-6 max-w-sm transform transition-transform duration-300 hover:scale-105">
            <img
              src="./img/support.jpg"
              alt="AI Assistance"
              className=" size-48 border rounded-full mx-auto md:px-0"
            />
            <h3 className="text-2xl font-semibold text-green-800 mb-4">
              Communication & Engagement
            </h3>
            <p className="text-gray-600">
              The app provides access to real-time advice from healthcare
              professionals, offering guidance whenever caregivers face
              challenging situations.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center mx-4 sm:mx-10 xl:mx-40 2xl:mx-44 max-w-7xl sm:2 lg:px-10 xl:px-20 rounded-lg shadow-lg py-6 bg-gray-50">
        <div className="md:w-3/5 w-full text-center md:text-left px-4">
          <h3 className="text-4xl md:text-5xl lg:text-6xl uppercase font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-blue-500">
            See Our App in Action
          </h3>
          <p className="text-lg md:text-xl text-justify text-gray-700 animate-fade-up mb-6 leading-relaxed">
            Watch this video to get a glimpse of how our platform can make a
            difference in the lives of autistic children and their families.
            Learn more about the features, benefits, and the positive impact it
            can bring to your daily life.
          </p>
        </div>
        <div className="md:w-2/5 w-full flex justify-center mb-8 md:mb-0">
          <video controls width="250" className="rounded-lg shadow-lg">
            <source src="./img/usVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div
        ref={subRef}
        className="py-12 text-center mb-10 main-container rounded-lg shadow-lg mx-4 mt-16"
      >
        <h3 className="text-3xl font-bold mb-4 animate-fade-in">
          Register for Early Access
        </h3>
        <p className="text-lg mb-6 animate-slide-up px-2 lg:px-20">
          Join our mission to improve autism care and awareness. Subscribe now
          to be among the first to access our app when it launches!
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 mx-8 sm:mx-10 lg:mx-40">
          <div className="flex flex-col w-full my-2 relative outline-none">
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
              className="px-4 py-[5px] my-auto rounded border border-gray-300 focus:outline-none"
            />
            <div className="absolute mt-36 md:mt-12 ml-2">
              {!validEmail && (
                <p className="email-msg text-red-500">
                  Enter a valid Email address
                </p>
              )}
              {subscribed && (
                <p className="subscribed-msg text-teal-600 w-[48vw]">
                  Subscribed Successfully!
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full relative">
            <PhoneInput
              defaultCountry={"in"}
              name="phone"
              value={formData.phone}
              onChange={handlePhoneNumberChange}
              required
            />
            <div className="absolute mt-28 md:mt-12 ml-2">
              {!validPhoneNumber && (
                <p className="phone-msg text-red-500">
                  Enter a valid Phone number
                </p>
              )}
            </div>
          </div>
          <button
            onClick={handleSubmition}
            className="bg-gradient-to-r w-full md:w-auto mt-2 md:mt-0 from-green-400 to-blue-400 text-white px-6 py-2 rounded-full transition duration-300 transform ease-in-out hover:scale-105"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default US;
